.image-gallery {
  width: 100%;
  height: 100%;
}
.image-gallery-content {
  width: 100%;
  height: 100%;
}
.image-gallery-slide-wrapper {
  width: 100%;
  height: 90%;
}
.image-gallery-slides {
  width: 100%;
  height: 100%;
}
.image-gallery-slide {
  width: 100%;
  height: 100%;
}

.image-gallery-slide img {
  width: 100%;
  height: 100%;
  max-height: 80vh;
  object-fit: contain;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}
